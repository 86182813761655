/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DatetimeView from 'vux/src/components/datetime-view';
import Flexbox from 'vux/src/components/flexbox/flexbox';
import FlexboxItem from 'vux/src/components/flexbox/flexbox-item';
import Popup from 'vux/src/components/popup';
import Tab from 'vux/src/components/tab/tab';
import TabItem from 'vux/src/components/tab/tab-item';
import TransferDom from 'vux/src/directives/transfer-dom';
import XButton from 'vux/src/components/x-button';
export default {
  name: 'EdDate',
  directives: {
    TransferDom: TransferDom
  },
  components: {
    Flexbox: Flexbox,
    FlexboxItem: FlexboxItem,
    XButton: XButton,
    Tab: Tab,
    TabItem: TabItem,
    DatetimeView: DatetimeView,
    Popup: Popup
  },
  props: {
    value: Boolean,
    currDate: String,
    monthShow: {
      type: Boolean,
      default: false
    },
    ismonthShow: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      confirm: false,
      monthFmt: 'YYYY-MM',
      daysFmt: 'YYYY-MM-DD',
      firstShow: true,
      monthDate: '',
      beginDate: '',
      endDate: '',
      activeDate: ''
    };
  },
  methods: {
    onMonthSelect: function onMonthSelect() {
      this.monthShow = true;
      this.$refs.monthView.render();
    },
    onDaysSelect: function onDaysSelect() {
      this.monthShow = false;
      this.$refs.beginView.render();
    },
    onBeginSelect: function onBeginSelect() {
      this.firstShow = true;
      this.$refs.beginView.render();
    },
    onEndSelect: function onEndSelect() {
      this.firstShow = false;
      this.$refs.endView.render();
    },
    onCancel: function onCancel() {
      this.confirm = false;
    },
    onConfirm: function onConfirm() {
      this.confirm = false;
      var beginDate = this.beginDate;
      var endDate = this.endDate;

      if (this.monthShow) {
        beginDate = this.$utils.DateUtils.getMonthStart(this.monthDate);
        endDate = this.$utils.DateUtils.getMonthEnd(this.monthDate);
      }

      this.$emit('onConfirm', beginDate, endDate);
    }
  },
  watch: {
    currDate: function currDate(val) {
      this.activeDate = this.$utils.DateUtils.getYYYYMMDD(val);
      this.monthDate = this.$utils.DateUtils.formatDateMonth(val);
      this.beginDate = this.$utils.DateUtils.getYYYYMMDD(this.$utils.DateUtils.getBeginWeek(val));
      this.endDate = this.$utils.DateUtils.beforesunday(this.beginDate, 6);
    },
    confirm: function confirm(val) {
      this.$emit('input', val);
    },
    activeDate: function activeDate(val) {
      this.beginDate = this.$utils.DateUtils.getYYYYMMDD(this.$utils.DateUtils.getBeginWeek(val));
      this.endDate = this.$utils.DateUtils.beforesunday(this.beginDate, 6);
    },
    // value(val) {
    //   this.confirm = val;
    // }
    value: {
      handler: function handler(val) {
        this.confirm = val;
      },
      immediate: true
    }
  }
};