/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    isOrderManyMeal: {
      type: Boolean,
      default: false
    },
    orderList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      money: 0
    };
  },
  watch: {
    isOrderManyMeal: {
      handler: function handler(newV) {
        if (newV) this.handleCount(this.orderList); //计算金额
      },
      deep: true
    }
  },
  methods: {
    //关闭订单
    closeDalogs: function closeDalogs() {
      this.$emit('closePopUp');
    },
    closeDalog: function closeDalog() {
      this.$emit('closePopUp');
      this.money = 0;
    },
    //修改订单
    confirmOrderMeal: function confirmOrderMeal() {
      this.$emit('paymentMeal', this.orderList);
    },
    //计算金额
    handleCount: function handleCount(list) {
      var _this = this;

      list.forEach(function (item) {
        var cash = _this.handleMoney(item.mealArr);

        _this.money += cash;
      });
    },
    handleMoney: function handleMoney(arr) {
      var count = 0;
      arr.forEach(function (item) {
        var _count = item.num * item.reportMoney;

        count += _count;
      });
      return count;
    }
  },
  created: function created() {},
  mounted: function mounted() {}
};