/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    isPayment: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    isSuccess: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  data: function data() {
    return {
      moeny: 1,
      amount: 0,
      //已支付
      price: 10,
      //单价
      monetary: 0,
      //消费金额
      balanceMoney: 0,
      //差额
      switchStatus: ''
    };
  },
  // watch: {
  //   isPayment: {
  //     handler(newV) {
  //       if (newV) {
  //         setTimeout(() => {
  //           this.$emit('closePay')
  //         }, 2000)
  //       }
  //     },
  //     deep: true,
  //     immediate: true
  //   },
  // },
  methods: {
    add: function add() {
      this.moeny++;
      this.monetary = this.moeny * this.price;
      this.balanceMoney = this.monetary - this.amount;
    },
    reduce: function reduce() {
      if (this.moeny == 1) return;
      this.moeny -= 1;
      this.monetary = this.moeny * this.price;
      this.balanceMoney = this.monetary - this.amount;
    },
    closeMoney: function closeMoney() {
      this.$emit('closePay');
    },
    comfirm: function comfirm() {
      this.$emit('comfirmOrder');
    },
    getSrcList: function getSrcList(aa) {
      this.switchStatus = aa;
    }
  },
  created: function created() {},
  mounted: function mounted() {}
};