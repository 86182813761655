/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    orderDetail: {
      type: Object,
      default: function _default() {}
    },
    detailsShow: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    //关闭订单
    closeDalog: function closeDalog() {
      this.$emit('closePopUpXiugai', this.orderDetail);
    },
    closeDalogs: function closeDalogs() {
      this.$emit('closePopUp');
    },
    //修改订单
    confirmOrderMeal: function confirmOrderMeal() {
      this.$emit('orderModif', this.orderDetail);
    }
  },
  created: function created() {},
  mounted: function mounted() {}
};