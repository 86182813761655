/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    verifyShow: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    orderBalance: {
      type: [String, Number],
      default: ''
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    cancle: function cancle() {
      this.$emit('closeConfirm');
    }
  },
  created: function created() {},
  mounted: function mounted() {}
};