/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    FoodInfoShow: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    orderDetail: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      moeny: 1,
      amount: 0,
      //已支付
      price: 10,
      //单价
      monetary: 0,
      //消费金额
      balanceMoney: 0 //差额

    };
  },
  watch: {
    FoodInfoShow: {
      handler: function handler(newV) {
        if (newV) {
          this.amount = this.orderDetail.money;
          this.monetary = this.orderDetail.orderNumber * this.orderDetail.reportMoney;
          this.balanceMoney = this.amount - this.monetary;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    add: function add() {
      this.orderDetail.orderNumber++;
      this.monetary = this.orderDetail.orderNumber * this.orderDetail.reportMoney;
      this.balanceMoney = this.monetary - this.amount;
    },
    reduce: function reduce() {
      if (this.orderDetail.orderNumber == 1) return;
      this.orderDetail.orderNumber -= 1;
      this.monetary = this.orderDetail.orderNumber * this.orderDetail.reportMoney;
      this.balanceMoney = this.monetary - this.amount;
    },
    closeMoney: function closeMoney() {
      this.$emit('closeMoney');
    },
    comfirm: function comfirm() {
      this.$emit('updataOder', this.orderDetail);
    }
  },
  created: function created() {},
  mounted: function mounted() {}
};