/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { Toast } from 'vant';
export default {
  props: {
    isOrderMeal: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    orderDetail: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      moeny: 1,
      amount: 0,
      //已支付
      price: 10,
      //单价
      monetary: 0,
      //消费金额
      balanceMoney: 0 //差额

    };
  },
  watch: {
    isOrderMeal: {
      handler: function handler(newV) {
        if (newV) {
          this.monetary = this.orderDetail.orderNumber * this.orderDetail.reportMoney;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    add: function add() {
      this.orderDetail.orderNumber++;
      this.monetary = this.orderDetail.orderNumber * this.orderDetail.reportMoney; // this.balanceMoney = this.monetary - this.amount
    },
    reduce: function reduce() {
      if (this.orderDetail.orderNumber == 0) {
        return;
      }

      this.orderDetail.orderNumber--;
      this.monetary = this.orderDetail.orderNumber * this.orderDetail.reportMoney; // this.balanceMoney = this.monetary - this.amount
    },
    closeMoney: function closeMoney() {
      this.$emit('closeMoney');
    },
    comfirm: function comfirm() {
      if (this.orderDetail.orderNumber == 0) {
        Toast("至少订一份");
        return;
      }

      this.$emit('comfirmOrder', this.orderDetail);
    }
  },
  created: function created() {},
  mounted: function mounted() {}
};